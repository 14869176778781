input[type='range'] {
  -webkit-appearance: none;
  margin: 0.5rem 0;
  width: 100%;
}
input[type='range']:focus {
  outline: none;
}

.RangeInput__Thumb {
  @apply w-6 h-6 bg-white shadow-md border border-solid border-gray-300 rounded-full cursor-pointer;
  -webkit-appearance: none;
}

/* These need to be seperate otherwise it doesnt work ._. */
input[type='range']::-webkit-slider-thumb {
  @apply RangeInput__Thumb;
  transform: translateY(calc(0.5rem - 2px));
  margin-top: -14px;
}

input[type='range']::-moz-range-thumb {
  @apply RangeInput__Thumb;
}

input[type='range']::-ms-thumb {
  @apply RangeInput__Thumb;
}

.RangeInput__Track {
  @apply w-full h-2 cursor-pointer rounded-xl;
}

input[type='range']:focus::-webkit-slider-runnable-track {
  @apply bg-yellow-500;
  @apply RangeInput__Track;
}

input[type='range']::-webkit-slider-runnable-track {
  @apply bg-yellow-500;
  @apply RangeInput__Track;
}

input[type='range']::-moz-range-track {
  @apply bg-yellow-500;
  @apply RangeInput__Track;
}

input[type='range']::-ms-track {
  @apply bg-yellow-500;
  @apply RangeInput__Track;
}
