@tailwind base;
@tailwind components;
@tailwind utilities;

@responsive {
  .shadow {
    @apply transition-shadow duration-200;
  }
}

html {
  scroll-behavior: smooth;
}

button {
  font: inherit;
}
button:focus {
  outline: none;
}
button:focus-visible {
  outline: 5px auto -webkit-focus-ring-color;
}

.grayscale {
  filter: grayscale(1);
}

select {
  background-image: url('/static/Icons/Down Arrow.svg');
  &:disabled {
    @apply opacity-100;
    background-image: none;
  }
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 50%;
}

.loader {
  border-top-color: #3498db;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

.break-words {
  word-break: break-word;
  word-wrap: break-word;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

input[type='checkbox']:checked + div {
  @apply bg-gray-900;
}

input:not(:placeholder-shown)::invalid {
  border-color: #ff0000;
}

input:not(:-ms-input-placeholder)::invalid {
  border-color: #ff0000;
}

.fill-screen {
  height: 100vh;
  height: -moz-available; /* WebKit-based browsers will ignore this. */
  height: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  height: stretch;
}

mark {
  background: none;
  color: currentColor;
  font-weight: bold;
}

figure.table {
  @apply flex overflow-x-auto;
}

.direction-switch {
  direction: ltr;
}

html[lang='ar'] .direction-switch {
  direction: rtl;
}

.direction-ltr {
  direction: ltr;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .NotSupportedBrowser {
    @apply flex items-center justify-center;
    @apply fixed inset-0;
    background: #f9f9fb;
    z-index: 1000;
    img {
      @apply w-40 h-40 m-4;
    }
  }
}

.htmlcontent {
  a {
    @apply underline;
  }
  ul {
    list-style: disc;
    @apply pl-4;
  }
}

.after\:empty-content::after {
  content: '';
}
