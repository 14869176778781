.Toggle {
  @apply relative cursor-pointer select-none;
  &__Track {
    @apply transition-colors bg-white w-12 h-6 mb-1 py-1 px-2 rounded-xl box-content border border-gray-300;
  }
  &__Thumb {
    @apply transition-transform duration-300 ease-in-out absolute top-1 left-2 w-6 h-6 bg-yellow-500 border border-yellow-600 rounded-full box-content shadow-md;
  }
}

input[type='checkbox']:checked ~ .Toggle__Thumb {
  transform: translateX(100%);
  @apply bg-white border-gray-500;
}

input[type='checkbox']:checked ~ .Toggle__Track {
  @apply border-yellow-600 bg-yellow-500;
}

input[type='checkbox']:disabled ~ .Toggle__Track {
  @apply bg-gray-500;
}

input[type='checkbox']:disabled ~ .Toggle__Thumb {
  @apply bg-gray-100 border-gray-500;
}
