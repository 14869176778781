.Slider::-webkit-scrollbar {
    display: none;
}

.Slider {
    -ms-overflow-style: none;
    scrollbar-width: none;
}
@responsive {
    .Slide {
        width: 75%;
    }

    @screen sm {
        .Slide {
            width: 50%;
        }
    }

    @screen md {
        .Slide {
            width: calc(calc(100% / 3) - 0.7rem);
        }
    }

    @screen lg {
        .Slide {
            width: calc(25% - 1rem);
        }
    }
}
