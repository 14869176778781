.Navigation {
  @apply pointer-events-none;
  > nav {
    @apply z-20;
    > ul {
      @apply pointer-events-auto;
    }
  }
  &__Blocker {
    @apply fixed inset-0 pointer-events-auto;
    #__next:not(.fixed) & {
      @apply hidden;
    }
  }
}

.Submenu,
.Submenu > .Submenu__Popup,
.Submenu > .Submenu__Blocker {
  @apply transition-transform;
  nav:not(.opened) & {
    @apply duration-500;
  }
}

.Submenu {
  @apply pointer-events-none;
  @apply absolute inset-0 -z-1;
  &__Popup {
    @apply pointer-events-auto;
    @apply absolute inset-x-0 top-0;
    @apply flex flex-col;
    @apply -mb-6;
    @apply bg-white shadow;
  }
  &__Blocker {
    @apply absolute inset-0 z-20;
    @apply bg-white;
  }
}

@media (max-width: calc(theme('screens.lg') - 1px)) {
  .Navigation {
    @apply fixed inset-0 top-auto z-20 bg-white;
    @apply shadow;
    @apply rounded-t-xl;
  }
  .Submenu__Popup {
    max-height: 450px;
    height: 62vh;
    @apply overflow-auto;
    @apply rounded-t-xl;
  }
  .Submenu {
    transform: translateY(100%);
    > .Submenu__Blocker {
      transform: translateY(-100%);
    }
    > .Submenu__Popup {
      transform: translateY(0);
    }
    &__Active {
      transform: translateY(0);
      > .Submenu__Blocker {
        transform: none;
      }
      > .Submenu__Popup {
        transform: translateY(-100%);
      }
    }
  }
}

@screen lg {
  .Navigation {
    width: 670px;
    @apply absolute top-0 bottom-0 right-0;
    @apply pl-2 overflow-hidden;
    @apply rounded-l-xl;
    > nav {
      @apply h-full;
      @apply pt-8 pr-4;
    }
  }
  .Submenu__Popup {
    width: 670px;
    @apply flex flex-col items-center justify-center;
    @apply h-full;
    @apply mb-0;
    @apply rounded-l-xl;
  }
  .Submenu__Blocker {
    @apply hidden;
  }
  @supports not (display: grid) {
    div {
      float: right;
    }
  }
  .Submenu .Submenu__Popup {
    transform: translateX(100%);
  }
  .Submenu__Active .Submenu__Popup {
    transform: translateX(0%);
  }
}
